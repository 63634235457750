import React, { lazy, Suspense, useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

// components
import FullPageLoader from '../components/FullPageLoader';
import { Card, Col, Drawer, FloatButton, Layout, Row, Segmented } from 'antd';

const Home = lazy(() => import('../views/Home'));
const Login = lazy(() => import('../views/Login'));
const TokenRoute = lazy(() => import('../views/Token'));
const Session = lazy(() => import('../views/Session'));
const Nudge = lazy(() => import('../views/Nudge'));
const NoPage = lazy(() => import('../views/404'));
const BehaviourSurvey = lazy(() => import('../views/BehaviourSurvey'));
const Header = lazy(() => import('../components/Layout/Header'));
const SuggestionBox = lazy(() => import('../components/SuggestionBox'));
const Recognition = lazy(() => import('../components/Recognition'));
const TouchPoint = lazy(() => import('../views/Touchpoint'));

// utils
import { useSession } from '../Providers/SessionProviders';
import { translate } from '../i18n/i18n';
import useWindowSize from '../hooks/window';

// constants
import {
  APP_ENV,
  DEFAULT_ACTIVE_SEGMENT,
  DEFAULT_ENVIRONMENT,
} from '../constant';
import { colorPalette } from '../theme';
import { FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;

const StyledContent = styled(Content)`
  padding: ${(props) => props.padding || '30px'};
  height: calc(100vh - 65px);
  overflow: auto;
  overflow-x: hidden;
`;

const StyledCard = styled(Card)`
  &.ant-card {
    border-radius: 0px;
    // height: calc(100vh - 60px);
  }
  .ant-card-body {
    padding-bottom: 0px;
  }
`;

const StyledSegment = styled(Segmented)`
  .ant-segmented-item-selected {
    background: #ecf5fe;
    border: 1px solid ${colorPalette.primary[500]};
    border-radius: 4px;
    color: ${colorPalette.primary[500]};
  }
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 10px;
  }
`;

const t = translate(['common']);

const NaschRoutes = () => {
  const { currentUser } = useSession();
  const { width } = useWindowSize();

  const [activeSegment, setActiveSegment] = useState(DEFAULT_ACTIVE_SEGMENT);
  const [openDrawer, setOpenDrawer] = useState(false);

  const pathPrefix = [DEFAULT_ENVIRONMENT.OPPORTUNE].includes(APP_ENV)
    ? '/:company'
    : '';

  const getBox = useCallback(() => {
    // if (APP_ENV === DEFAULT_ENVIRONMENT.DEMO) {
    //   return activeSegment === 0 ? (
    //     <DemoSuggestionBox isDrawer={openDrawer} />
    //   ) : (
    //     <DemoRecognition isDrawer={openDrawer} />
    //   );
    // }
    if (activeSegment === 0 && currentUser?.company?.suggestion_enabled) {
      return <SuggestionBox isDrawer={openDrawer} />;
    }
    if (activeSegment === 1 && currentUser?.company?.recognition_enabled) {
      return <Recognition isDrawer={openDrawer} />;
    }
  }, [openDrawer, activeSegment, currentUser]);

  const getSegmentItems = useCallback(() => {
    const data = [];
    if (currentUser?.company?.suggestion_enabled) {
      data.push({ label: t('suggestionBox'), value: 0 });
    }
    if (currentUser?.company?.recognition_enabled) {
      data.push({ label: t('recognition'), value: 1 });
    }
    return data;
  }, [currentUser]);

  const allRoutes = (
    <Routes>
      <Route path={`${pathPrefix}/`} element={<Home />} />
      <Route path={`${pathPrefix}/login/:slug`} element={<Login />} />
      <Route path={`${pathPrefix}/nudge/:nudgeId`} element={<Nudge />} />
      <Route path={`${pathPrefix}/session/:sessionId`} element={<Session />} />
      <Route
        path={`${pathPrefix}/touchpoint/:sessionId`}
        element={<TouchPoint />}
      />
      <Route path={`${pathPrefix}/behaviour`} element={<BehaviourSurvey />} />
      <Route path={`${pathPrefix}/alignment`} element={<BehaviourSurvey />} />
      <Route path={`${pathPrefix}*`} element={<NoPage />} />
    </Routes>
  );

  if (!currentUser) {
    return (
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route path={`${pathPrefix}/login/:slug`} element={<Login />} />
          <Route
            path={`${pathPrefix}/nudge/:nudgeId`}
            element={<TokenRoute />}
          />
          <Route
            path={`${pathPrefix}/session/:sessionId`}
            element={<TokenRoute />}
          />
          <Route path={`${pathPrefix}/behaviour`} element={<TokenRoute />} />
          <Route path={`*`} element={<NoPage />} />
        </Routes>
      </Suspense>
    );
  }

  if (width <= 820) {
    return (
      <Suspense fallback={<FullPageLoader />}>
        <Layout id="layout">
          <Header />
          <StyledContent padding="10px">{allRoutes}</StyledContent>
          {currentUser?.company?.suggestion_enabled && (
            <FloatButton
              icon={<QuestionCircleOutlined />}
              type="primary"
              shape="square"
              style={{
                right: currentUser?.company?.recognition_enabled ? 55 : 10,
              }}
              onClick={() => {
                setOpenDrawer(true);
                setActiveSegment(0);
              }}
            />
          )}
          {currentUser?.company?.recognition_enabled && (
            <FloatButton
              icon={<FormOutlined />}
              type="primary"
              shape="square"
              style={{ right: 10 }}
              // tooltip={t('recognition')}
              onClick={() => {
                setOpenDrawer(true);
                setActiveSegment(1);
              }}
            />
          )}
          {(currentUser?.company?.suggestion_enabled ||
            currentUser?.company?.recognition_enabled) && (
            <StyledDrawer
              title={
                activeSegment === 1 ? t('recognition') : t('suggestionBox')
              }
              width={width}
              onClose={() => {
                setOpenDrawer(false);
                setActiveSegment(0);
              }}
              open={openDrawer}
              destroyOnClose>
              {openDrawer && getBox()}
            </StyledDrawer>
          )}
        </Layout>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<FullPageLoader />}>
      <Layout id="layout">
        <Header />
        <Row>
          <Col
            span={
              currentUser?.company?.suggestion_enabled ||
              currentUser?.company?.recognition_enabled
                ? 16
                : 24
            }>
            <StyledContent padding="10px">{allRoutes}</StyledContent>
          </Col>
          {(currentUser?.company?.suggestion_enabled ||
            currentUser?.company?.recognition_enabled) && (
            <Col span={8}>
              <StyledCard>
                <StyledSegment
                  block
                  value={activeSegment}
                  options={getSegmentItems()}
                  onChange={(e) => setActiveSegment(e)}
                />
                <Wrapper>{getBox()}</Wrapper>
              </StyledCard>
            </Col>
          )}
        </Row>
      </Layout>
    </Suspense>
  );
};

export default NaschRoutes;
