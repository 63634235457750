import axios from 'axios';
import { generateLinkText, getAuthToken, removeAuthToken } from './utils';

axios.interceptors.request.use((config) => {
  const token = getAuthToken() || null;

  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
    'X-XSS-Protection': '1; mode=block', // Helps prevent reflected XSS attacks
    'X-Frame-Options': 'DENY',
    'Content-Security-Policy': "frame-ancestors 'self';",
  };
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (response.data && typeof response.data === 'string') {
      response.data = DOMPurify.sanitize(response.data);
    }
    return response.data;
  },
  async (error) => {
    const { response } = error;
    if (response?.status === 401) {
      removeAuthToken();
      window.location = generateLinkText('/unauthorized');
    }
    return Promise.reject(error?.response || error);
  },
);
