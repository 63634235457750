import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const SessionContext = createContext();

export function SessionProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [showHeaderLoading, setShowHeaderLoading] = useState(false);
  const [showSuggestionBoxTour, setShowSuggestionBoxTour] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(true);
  const [suggestionBoxMessage, setSuggestionBoxMessage] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(true);

  const value = {
    currentUser,
    setCurrentUser,
    isLoading,
    setIsLoading,
    loginLoading,
    setLoginLoading,
    showHeaderLoading,
    setShowHeaderLoading,
    showSuggestionBoxTour,
    setShowSuggestionBoxTour,
    suggestionBoxMessage,
    setSuggestionBoxMessage,
    showLanguageDropdown,
    setShowLanguageDropdown,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}

SessionProvider.propTypes = {
  children: PropTypes.node,
};

export function useSession() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
}
