import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

// Component
import { ConfigProvider, Drawer, Modal } from 'antd';
import FullPageLoader from './components/FullPageLoader';
import ErrorBoundary from './components/ErrorBoundary';

// Routes
import NaschRoutes from './router/default';
import DemoRoutes from './router/demo';

// constant
import {
  API_URL,
  APP_ENV,
  DEFAULT_ENVIRONMENT,
  MAHINDRA_SLUG,
} from './constant';
import theme from './theme';

// utils
import {
  generateLinkText,
  getAuthToken,
  getCompanySlugFromURL,
  removeAuthToken,
} from './utils';
import { useSession } from './Providers/SessionProviders';
import { translate } from './i18n/i18n';
import useWindowSize from './hooks/window';
import SelectLanguage from './components/SelectLanguage';

const t = translate(['common']);

function App() {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading,
    currentUser,
    setIsLoading,
    setCurrentUser,
    setLoginLoading,
  } = useSession();

  useEffect(() => {
    const { pathname, search } = window.location;
    if (pathname.includes('/login/') || search.includes('?token=')) {
      removeAuthToken();
      setCurrentUser(null);
      navigate(pathname + search, { replace: true });
    }
    const token = getAuthToken();
    if (token) {
      fetchUserDetails();
    } else {
      // navigate(generateLinkText('/unauthorized'));
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWhatsappStatus = async (sessionId) => {
    await axios.post(`${API_URL}/stats/whatsapp-status/${sessionId}/`, {
      status: 'interacted',
    });
    setSearchParams({}, { replace: true });
  };

  const fetchUserDetails = async () => {
    setIsLoading(true);
    try {
      const slug = getCompanySlugFromURL();
      const response = await axios.get(
        `${API_URL}/accounts/profile/?company=${slug}`,
      );
      setCurrentUser(response);
      if (searchParams.get('interacted')) {
        updateWhatsappStatus(searchParams.get('interacted'));
      }
      // navigate(generateLinkText('/'));
      if (
        location.pathname.includes('/not-found') ||
        location.pathname.includes('/unauthorized')
      ) {
        navigate(generateLinkText('/'));
      }
    } catch (error) {
      console.error(error);
      navigate(generateLinkText('/unauthorized'));
    } finally {
      setIsLoading(false);
      setLoginLoading(false);
    }
  };

  if (isLoading) return <FullPageLoader />;

  if (
    currentUser?.company?.slug == MAHINDRA_SLUG &&
    (currentUser?.company?.languages || []).length &&
    !currentUser?.language
  ) {
    return (
      <>
        <ConfigProvider theme={theme}>
          <ErrorBoundary>
            <SelectLanguage />
          </ErrorBoundary>
        </ConfigProvider>
      </>
    );
  }

  return (
    <ConfigProvider theme={theme}>
      <ErrorBoundary>
        {APP_ENV === DEFAULT_ENVIRONMENT.DEMO ? (
          <DemoRoutes />
        ) : (
          <NaschRoutes />
        )}
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
